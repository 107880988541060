<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="notification-list-container">
    <b-card no-body>
      <b-card-header>
        <b-card-title>Thông báo</b-card-title>

      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col cols="12">
            <b-form-input
              v-model="searchTerm"
              placeholder="Tìm kiếm..."
              debounce="1000"
            />
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col cols="12">
            <app-timeline>
              <app-timeline-item
                v-for="notification in rows"
                :key="notification.id"
                :title="notification.title"
                :subtitle="notification.body"
                icon="BellIcon"
                :time="parseDateToString(notification.created_at)"
                variant="success"
              />
            </app-timeline>
          </b-col>

        </b-row>

      </b-card-body>
    </b-card>
    <!-- Task Handler -->
    <!-- <notification-handler
      v-model="isHandlerSidebarActive"
      :notification="item"
      :clear-notification-data="resetItem"
      @create-notification="createNotification"
      @update-notification="updateNotification"
    /> -->
    <notification-modal
      :item="item"
      @refetch-data="fetchNotifications"
    />
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BRow,
  BCol,
  BFormInput,

} from 'bootstrap-vue';
// eslint-disable-next-line import/no-cycle
import { parseDateToString } from '@/auth/utils';
import AppTimeline from '@/@core/components/app-timeline/AppTimeline.vue';
import AppTimelineItem from '@/@core/components/app-timeline/AppTimelineItem.vue';
import useNotificationList from './useSystemNotificationList';
import NotificationModal from '../modal/NotificationModal.vue';

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    NotificationModal,
    AppTimeline,
    AppTimelineItem,
  },

  setup() {
    const {
      isHandlerSidebarActive,
      item,
      columns,
      rows,
      type,
      selectedRows,
      searchTerm,
      isLoading,
      totalRecords,
      serverParams,
      fetchNotifications,
      deleteNotifications,
      createNotification,
      updateNotification,
      onSelectNotification,
      resetItem,
      onEditItem,
      updateParams,
      onPageChange,
      onPerPageChange,
      onSortChange,
      onColumnFilter,
      selectionChanged,
      resolveMethods,
    } = useNotificationList();

    return {
      isHandlerSidebarActive,
      item,
      columns,
      rows,
      type,
      selectedRows,
      searchTerm,
      isLoading,
      totalRecords,
      serverParams,

      resetItem,
      onEditItem,
      fetchNotifications,
      deleteNotifications,
      createNotification,
      updateNotification,
      onSelectNotification,

      updateParams,
      onPageChange,
      onPerPageChange,
      onSortChange,
      onColumnFilter,
      selectionChanged,
      resolveMethods,
      parseDateToString,
    };
  },

  created() {
    this.fetchNotifications();
  },

  methods: {
    showCreateNotification() {
      this.isHandlerSidebarActive = true;
    },
    onDelete(notification) {
      const deleteObjects = notification && notification.id > 0 ? [notification] : this.selectedRows;
      this.deleteNotifications(deleteObjects);
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
.vgt-wrap {
  .vgt-table {
    thead {
      th {
        font-size: 1rem !important;
        font-weight: 500;
      }
    }
    td {
      font-size: 1rem !important;
      font-weight: 500;
    }
  }
}
.notification-list-container {
  .vgt-table {
      thead {
        tr {
          th {
            min-width: 100px !important;

            &:first-child {
              min-width: 50px !important;
            }
            &:nth-child(2) {
               min-width: 80px !important;
            }
            &:nth-child(3) {
               min-width: 80px !important;
            }
            &:nth-child(4) {
               min-width: 150px !important;
            }
            &:nth-child(5) {
               min-width: 240px !important;
            }
            &:nth-child(6) {
               min-width: 140px !important;
            }
            &:nth-child(7) {
               min-width: 130px !important;
            }
          }
        }
      }
    }
}
</style>
