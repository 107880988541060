import { ref, watch, onUnmounted } from '@vue/composition-api';
import store from '@/store';

import toast from '@/utils/toast';
import notificationStoreModule from '../notificationStoreModule';

export default function useNotificationList() {
  const STORE_MODULE_NAME = 'notification';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, notificationStoreModule);
  }

  // UnRegister on leave
  onUnmounted(() => {
    if (store.hasModule(STORE_MODULE_NAME)) {
      store.unregisterModule(STORE_MODULE_NAME);
    }
  });
  // Use toast
  const toastification = toast();

  // Table Handlers
  const columns = [
    {
      label: 'Mã',
      field: 'code',
    },
    {
      label: 'Thao tác',
      field: 'action',
      tdClass: 'text-center',
      sortable: false,
      width: '100px',
    },
    {
      label: 'Phạm vi nhận',
      field: 'recipents',
      sortable: false,
    },
    {
      label: 'Nội dung',
      field: 'content',
    },
    {
      label: 'Thời gian gửi',
      field: 'created_at',
    },
    {
      label: 'Hình thức gửi',
      field: 'methods',
    },
    {
      label: 'Trạng thái',
      field: 'status',
    },

  ];
  const rows = ref([]);
  // filter
  const type = ref(null);
  // current selected rows
  const selectedRows = ref([]);
  const searchTerm = ref('');
  // ssr
  const isLoading = ref(false);
  const totalRecords = ref(0);
  const serverParams = ref({
    searchTerm: '',
    filter: {},
    sort: {},
    page: 1,
    perPage: 20,
  });

  //   handler
  const isHandlerSidebarActive = ref(false);

  //   notification
  const blankItem = {
    id: 0,
    title: '',
    content: '',
    attachments: [],
    apartment: null,
    room: null,
    bed: null,
    methods: [],
    deleteAttachmentIds: [],
    tenants: [],
  };
  const item = ref(JSON.parse(JSON.stringify(blankItem)));
  const resetItem = () => {
    item.value = JSON.parse(JSON.stringify(blankItem));
  };

  //   API Call
  const fetchNotifications = () => {
    store
      .dispatch('notification/fetchSystemNotifications', serverParams.value)
      .then(response => {
        const { total, items } = response.data;
        totalRecords.value = total;
        rows.value = items;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const onSelectNotification = object => {
    item.value = object;
    isHandlerSidebarActive.value = true;
  };

  const onEditItem = val => {
    item.value = val;
  };

  //   Methods
  const updateParams = newProps => {
    serverParams.value = { ...serverParams.value, ...newProps };
    fetchNotifications();
  };

  const onPageChange = params => {
    updateParams({ page: params.currentPage });
  };

  const onPerPageChange = params => {
    updateParams({ perPage: params.currentPerPage });
  };

  const onSortChange = params => {
    updateParams({
      sort: {
        type: params[0].type,
        field: params[0].field,
      },
    });
  };

  const onColumnFilter = params => {
    updateParams(params);
  };

  const selectionChanged = params => {
    selectedRows.value = params.selectedRows;
  };

  //   Watch
  watch(searchTerm, val => {
    updateParams({ searchTerm: val });
  });
  watch(type, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.cash = val.value;
    } else {
      delete filter.cash;
    }
    updateParams({ filter });
  });

  // UI
  const resolveMethods = method => {
    if (method === 'app') {
      return { title: 'App', variant: 'light-primary' };
    }
    if (method === 'email') {
      return { title: 'Email', variant: 'light-info' };
    }
    if (method === 'zalo') {
      return { title: 'Zalo', variant: 'light-success' };
    }
    if (method === 'sms') {
      return { title: 'SMS', variant: 'light-secondary' };
    }
    return { title: 'App', variant: 'light-primary' };
  };

  return {
    isHandlerSidebarActive,
    item,
    columns,
    rows,
    type,
    selectedRows,
    searchTerm,
    isLoading,
    totalRecords,
    serverParams,

    onSelectNotification,
    resetItem,
    onEditItem,
    fetchNotifications,

    updateParams,
    onPageChange,
    onPerPageChange,
    onSortChange,
    onColumnFilter,
    selectionChanged,

    resolveMethods,
  };
}
